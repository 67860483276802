import Search from "./search";
import instrumentsData from "./instruments.json";
import { Bar, ResolutionString } from "../charting_library/charting_library.js";
import { Resolutions } from "../constants";
import {
  IHolidaysAndSpecialTradingDays,
  SocketSubscription,
} from "../types.js";

const getCookieValue = (name: string): string => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = window.document.cookie.match(regex);
  if (match) {
    return match[2];
  }
  return "";
};
const queryParams = new URLSearchParams(window.location.search);

// Will contain frequently accessed data
class Store {
  private searchInstance: Search;
  private authToken: string;
  private authSession: string;
  private lastBarCache: Map<ResolutionString, Map<number, Bar>>;
  private ticker_Token: Map<string, number>;
  private subid_Token: Map<string, number>;
  private socketSubscriptions: Map<
    number,
    Map<ResolutionString, SocketSubscription>
  >;
  private holidaysAndSpecialTradingDays: IHolidaysAndSpecialTradingDays;

  constructor() {
    // defaults -----------------------
    this.searchInstance = new Search();
    this.searchInstance.init(<any>instrumentsData);

    // user related ----------
    this.authSession = queryParams.get("session") || getCookieValue("session");
    this.authToken =
      queryParams.get("token") ||
      queryParams.get("accessToken") ||
      getCookieValue("token") ||
      getCookieValue("accessToken");

    // caches -----------------
    this.lastBarCache = new Map();
    this.lastBarCache.set(Resolutions[1], new Map<number, Bar>());
    this.lastBarCache.set(Resolutions[3], new Map<number, Bar>());
    this.lastBarCache.set(Resolutions[5], new Map<number, Bar>());
    this.lastBarCache.set(Resolutions[10], new Map<number, Bar>());
    this.lastBarCache.set(Resolutions[15], new Map<number, Bar>());
    this.lastBarCache.set(Resolutions[30], new Map<number, Bar>());
    this.lastBarCache.set(Resolutions[60], new Map<number, Bar>());
    this.lastBarCache.set(Resolutions["1D"], new Map<number, Bar>());
    this.lastBarCache.set(Resolutions["1W"], new Map<number, Bar>());
    this.lastBarCache.set(Resolutions["1M"], new Map<number, Bar>());

    // ticker to token
    this.ticker_Token = new Map();
    this.subid_Token = new Map();

    // socket subscriptions
    this.socketSubscriptions = new Map();
  }

  getSearchInstance(): Search {
    return this.searchInstance;
  }

  getAuthToken(): string {
    return this.authToken;
  }

  getAuthSession(): string {
    return this.authSession;
  }

  getLastBar(resolution: ResolutionString, symbolToken: number) {
    return this.lastBarCache.get(resolution)?.get(symbolToken);
  }
  setLastBar(resolution: ResolutionString, symbolToken: number, bar: Bar) {
    this.lastBarCache.get(resolution)?.set(symbolToken, bar);
  }

  getSocketSubscription(symbolToken: number, resolution: ResolutionString) {
    return this.socketSubscriptions.get(symbolToken)?.get(resolution);
  }

  getAllSocketSubscriptions() {
    return this.socketSubscriptions;
  }

  setSocketSubscription(
    symbolToken: number,
    resolution: ResolutionString,
    subscription: SocketSubscription
  ) {
    if (!this.socketSubscriptions.has(symbolToken)) {
      // no such symbolToken yet. create new entry
      this.socketSubscriptions.set(
        symbolToken,
        new Map<ResolutionString, SocketSubscription>()
      );
    }

    // update symbol token with a subscription for a resolution
    this.socketSubscriptions.get(symbolToken)?.set(resolution, subscription);
  }

  setTokenToTicker(ticker: string, token: number) {
    this.ticker_Token.set(ticker, token);
  }
  getTokenFromTicker(ticker: string) {
    return this.ticker_Token.get(ticker);
  }

  setTokenToSubid(subid: string, token: number) {
    this.subid_Token.set(subid, token);
  }

  getTokenFromSubid(subid: string) {
    return this.subid_Token.get(subid);
  }

  async getHolidaysFromBackend(): Promise<IHolidaysAndSpecialTradingDays> {
    if (this.holidaysAndSpecialTradingDays) {
      return this.holidaysAndSpecialTradingDays;
    }
    try {
      let response = await fetch(`${process.env.OMS_URL}info/holidays`, {
        headers: {
          session: this.authSession,
          token: this.authToken,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(JSON.stringify(response));
      }
      this.holidaysAndSpecialTradingDays = data.data;
      return this.holidaysAndSpecialTradingDays;
    } catch (err) {
      console.error("⛔ error fetching holidays:", err);
      this.holidaysAndSpecialTradingDays = {
        holidays: {},
        specialTradingDays: {},
      };
    }

    return this.holidaysAndSpecialTradingDays;
  }
}

const store = new Store();
export default store;

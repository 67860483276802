import Store from "./store";
import {
  Environment,
  MODE,
  Segment,
} from "../constants";
import {
  Bar,
  PeriodParams,
  ResolutionString,
} from "../charting_library/charting_library";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Resolutions } from "../constants";
import {
  BackendBars,
  CustomBar,
  IHolidaysAndCorrections,
  IHolidaysAndSpecialTradingDays,
  Tick,
} from "../types";
import { configurationData } from "./handlers";
import store from "./store";
import { handle401 } from "../helpers";
import PQueue from "p-queue";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);
dayjs.tz.setDefault("Asia/Kolkata");

/* -------------------------------------------------------
splits symbol name and returns [exchange,ticker,isOpenInterest]
*/
export const extractDataFromSymbolName = (
  symbolName: string
): [string, string, string, boolean] => {
  let segment2 = "";
  let exchange = "";
  let ticker = "";
  let isOpenInterest = false;
  const split = symbolName.split(":");
  const exchangeDuo = split[0].split("|");
  if (exchangeDuo.length == 2) {
    segment2 = exchangeDuo[0];
    exchange = exchangeDuo[1];
  } else {
    exchange = exchangeDuo[0];
  }
  ticker = split[1];
  if (split[2]) {
    isOpenInterest = !!split[2].length;
  }
  return [segment2, exchange, ticker, isOpenInterest];
};

// -------------------------------------------------------
export const getSegment = (
  tickerParams: string,
  exchangeParams: string
): Segment => {
  let segmentToReturn = "NSE";
  const search = Store.getSearchInstance();
  Store.getSearchInstance().segmentsList.map((segment: string) => {
    if (exchangeParams === "NSE" && segment === "BSE") return;
    if (exchangeParams === "BSE" && segment === "NSE") return;
    if (search.instrumentsMap[segment] ? search.instrumentsMap[segment][tickerParams] : false) {
      segmentToReturn = segment;
    }
  });
  return <Segment>segmentToReturn;
};

// divides dates into chunks
export const prepareDataRanges = async (
  from: number,
  to: number,
  resolution: ResolutionString
) => {
  const dates: { from: number; to: number }[] = [];

  let minDuration = getBackendResolution(resolution)?.minDuration;
  if (!minDuration) return [];

  const holidaysAndSpecialTradingDays = await store.getHolidaysFromBackend();

  let hour = 9;
  let minute = 15;

  // daily weekly monthly
  if (/[DWM]/.test(resolution)) {
    hour = 5;
    minute = 30;

    // todays date, modifying "to" time as per backend requirement
    const toDayjs = dayjs(to * 1000).tz();
    const tsNow = dayjs().tz();
    if (toDayjs.format("DD/MM/YYYY") === tsNow.format("DD/MM/YYYY"))
      // if today
      to = toDayjs
        .set("hour", tsNow.hour())
        .set("minute", tsNow.minute() + 1)
        .set("second", tsNow.minute() + 1 === 60 ? 0 : tsNow.second())
        .unix();
  }

  while (true) {
    // modify dates to satisfy minimum bars to get
    let startFrom = dayjs(to * 1000).tz()
      .subtract(minDuration, "day")
      .set("hour", hour)
      .set("minute", minute)
      .set("second", 0);

    // excluding holiday in "startFrom"
    let fromStr = startFrom.format("DD-MM-YYYY");
    let isWeekend = startFrom.day() === 0 || startFrom.day() === 6;

    // -1 day if startFrom is a holiday.
    while (holidaysAndSpecialTradingDays.holidays[fromStr] || isWeekend) {
      startFrom = startFrom.subtract(1, "day");
      fromStr = startFrom.format("DD-MM-YYYY");
      isWeekend = startFrom.day() === 0 || startFrom.day() === 6;
    }

    const startFromUnix = startFrom.unix();
    // push this chunk
    dates.push({ from: startFromUnix, to: to });
    // prepare for next iteration
    to = startFromUnix;

    // if startFrom has gotten below or equal to required "from" date. We have got our ranges. exit
    if (from >= startFromUnix) {
      break;
    }
  }

  return dates.reverse();
};

/* ---------------------------------------------------------
Returns backend supported resolution corresponding to tv charts resolution
*/
export const getBackendResolution = (resolution: ResolutionString) => {
  let serviceResolution;
  let dataLimitYear;
  let minDuration;

  switch (resolution) {
    case Resolutions[1]: {
      serviceResolution = "min";
      minDuration = 10; // In days
      break;
    }
    case Resolutions[3]: {
      serviceResolution = "3min";
      minDuration = 10; // In days
      break;
    }
    case Resolutions[5]: {
      serviceResolution = "5min";
      minDuration = 10; // In days
      break;
    }
    case Resolutions[10]: {
      serviceResolution = "10min";
      minDuration = 15; // In days
      break;
    }
    case Resolutions[15]: {
      serviceResolution = "15min";
      minDuration = 20; // In days
      break;
    }
    case Resolutions[30]: {
      serviceResolution = "30min";
      minDuration = 30; // In days
      break;
    }
    case Resolutions[60]: {
      serviceResolution = "hour";
      minDuration = 60; // In days
      break;
    }
    case Resolutions["1D"]: {
      serviceResolution = "day";
      minDuration = 1 * 12 * 30; // In days
      break;
    }
    case Resolutions["1W"]: {
      serviceResolution = "week";
      minDuration = 2 * 12 * 30; // In days
      break;
    }
    case Resolutions["1M"]: {
      serviceResolution = "month";
      minDuration = 4 * 12 * 30; // In days
      break;
    }
    default: {
      error(`[getNextBarTime] Resolution not supported: ${resolution}`);
      return undefined;
    }
  }
  return { serviceResolution, dataLimitYear, minDuration: minDuration };
};

/* ------------------------------------------------------------
  Generates random bars for test 
  !NOTE: Will only work if session is set to 24x7
*/
export const getFakeBars = (periodParams: PeriodParams): Bar[] => {
  // We are constructing an array for `countBack` bars.
  const bars: Bar[] = new Array(periodParams.countBack);

  // For constructing the bars we are starting from the `to` time minus 1 day, and working backwards until we have `countBack` bars.
  let time = new Date(periodParams.to * 1000);
  time.setUTCHours(0);
  time.setUTCMinutes(0);
  time.setUTCMilliseconds(0);
  time.setUTCDate(time.getUTCDate() - 1);

  // Fake price.
  let price = 100;

  for (let i = periodParams.countBack - 1; i > -1; i--) {
    bars[i] = {
      open: price,
      high: price,
      low: price,
      close: price,
      time: time.getTime(),
    };

    // Working out a random value for changing the fake price.
    const volatility = 0.1;
    const x = Math.random() - 0.5;
    const changePercent = 2 * volatility * x;
    const changeAmount = price * changePercent;
    price = price + changeAmount;

    // Note that this simple "-1 day" logic only works because the TEST symbol has a 24x7 session.
    // For a more complex session we would need to, for example, skip weekends.
    time.setUTCDate(time.getUTCDate() - 1);
  }
  return bars;
};

// max 10 requests per second.
const queue = new PQueue({ concurrency: 10, intervalCap: 10, interval: 1000 });
/* ----------------------------------------------------------------
 Returns bars in ohlcv format. May also include OI value if requested
*/
export const getBarsFromBackend = async (
  exchange: Segment,
  token: number,
  resolution: ResolutionString,
  from: number,
  to: number,
  isOI: boolean
): Promise<BackendBars> => {
  return queue.add(async () => {
    // fetching candle url from .env
    const candleURL = process.env.CANDLE_URL;
    if (!candleURL) {
      throw new Error("Candle URL is not defined");
    }

    // parsing seconds to timestamps;
    let toTS = dayjs(to * 1000).tz();
    let fromTS = dayjs(from * 1000).tz();

    // getting corresponding backend supported resolution for tv charts resolution
    let backendResolution = getBackendResolution(resolution);
    if (!backendResolution) {
      throw new Error("[getBarsFromBackend] Backend resolution not supported");
    }
    const serviceResolution = backendResolution.serviceResolution;

    // Is IO data required. 1 is yes, 0 is no
    const oi = +isOI;

    // Format date as per backend.
    const backendTimeFormat = "YYYY-MM-DDTHH:mm:ss";
    const formattedTo = toTS.format(backendTimeFormat);
    const formattedFrom = fromTS.format(backendTimeFormat);

    // bars endpoint
    const url = `${candleURL}${exchange.toLowerCase()}/${token}/${serviceResolution}?from=${formattedFrom}&to=${formattedTo}&oi=${oi}`;
    log("🔗 Requesting bars from backend at ", url);

    // request for bars
    let response = await fetch(url, {
      headers: {
        session: Store.getAuthSession(),
        token: Store.getAuthToken(),
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        handle401();
      }
      throw new Error(
        `Failed to fetch bars from backend, received: ${response.status}`
      );
    }

    return response.json();
  });
};

// console.log only on dev & stg --------------------------------------
export const log = (message?: any, ...optionalParams: any[]) => {
  if (
    process.env.NODE_ENV === Environment.DEVELOPMENT ||
    process.env.NODE_ENV === Environment.STAGING
  ) {
    console.log(message, ...optionalParams);
  }
};

// console.error only on dev & stg -----------------------------------
export const error = (message?: any, ...optionalParams: any[]) => {
  if (
    process.env.NODE_ENV === Environment.DEVELOPMENT ||
    process.env.NODE_ENV === Environment.STAGING
  ) {
    console.error(message, ...optionalParams);
  }
};

// bigEndianToInt -----------------------------------------------
const bigEndianToInt = (buffer: ArrayBuffer) => {
  const buf = new Uint8Array(buffer);
  let value = 0;
  const len = buf.byteLength;
  for (let i = 0, j = len - 1; i < len; i++, j--) {
    value += buf[j] << (i * 8);
  }
  return value;
};

// parseBinary -------------------------------------------------
// export function parseBinary(data: ArrayBuffer) {
//   const tick:Tick = {};
//   if (data.byteLength >= 17) {
//     tick.token = bigEndianToInt(data.slice(0, 4));
//     tick.ltp = bigEndianToInt(data.slice(4, 8));
//     if (data.byteLength === 17) {
//       tick.close = bigEndianToInt(data.slice(13, 17));
//       tick.netChange =
//         Math.round(
//           (((tick.ltp - tick.close) / tick.close) * 100 + Number.EPSILON) *
//           100
//         ) / 100 || 0;
//       if (tick.ltp > tick.close) {
//         tick.changeFlag = 43; // ascii code for +
//       } else if (tick.ltp < tick.close) {
//         tick.changeFlag = 45; // ascii code for -
//       } else {
//         tick.changeFlag = 32; // no change
//       }
//     }
//     tick.mode = MODE.LTPC;
//   }
//   if (data.byteLength >= 77) {
//     tick.ltq = bigEndianToInt(data.slice(13, 17));
//     tick.avgPrice = bigEndianToInt(data.slice(17, 21));
//     tick.totalBuyQuantity = bigEndianToInt(data.slice(21, 29));
//     tick.totalSellQuantity = bigEndianToInt(data.slice(29, 37));
//     tick.open = bigEndianToInt(data.slice(37, 41));
//     tick.high = bigEndianToInt(data.slice(41, 45));
//     tick.close = bigEndianToInt(data.slice(45, 49));
//     tick.low = bigEndianToInt(data.slice(49, 53));
//     tick.volume = bigEndianToInt(data.slice(53, 57));
//     tick.time = bigEndianToInt(data.slice(61, 65));
//     tick.oi = bigEndianToInt(data.slice(65,69));
//     tick.oiDayHigh = bigEndianToInt(data.slice(69,73));
//     tick.oiDayLow = bigEndianToInt(data.slice(73,77));
//     tick.netChange =
//       Math.round(
//         (((tick.ltp! - tick.close) / tick.close) * 100 + Number.EPSILON) * 100
//       ) / 100 || 0;
//     if (tick.ltp! > tick.close) {
//       tick.changeFlag = 43; // ascii code for +
//     } else if (tick.ltp! < tick.close) {
//       tick.changeFlag = 45; // ascii code for -
//     } else {
//       tick.changeFlag = 32; // no change
//     }
//   tick.mode = MODE.QUOTE;
//   }
//   if (data.byteLength === 225) {
//     tick.ltt = bigEndianToInt(data.slice(57, 61));
//     tick.lowerLimit = bigEndianToInt(data.slice(77, 81));
//     tick.upperLimit = bigEndianToInt(data.slice(81, 85));
//     const bids = [];
//     const asks = [];
//     for (let i = 0; i < 10; i++) {
//       const quantity = bigEndianToInt(data.slice(85 + i * 14, 93 + i * 14));
//       const price = bigEndianToInt(data.slice(93 + i * 14, 97 + i * 14));
//       const orders = bigEndianToInt(data.slice(97 + i * 14, 99 + i * 14));
//       if (i >= 5) {
//         asks.push({ price, quantity, orders });
//       } else {
//         bids.push({ price, quantity, orders });
//       }
//     }
//     tick.bids = bids;
//     tick.asks = asks;
//     tick.mode = MODE.FULL;
//   }
//   if (tick.close === 0) {
//     tick.netChange = 0;
//   }
//   return tick;
// }

// parseBinaryUpdated -------------------------------------------------
export function parseBinary(data: ArrayBuffer) {
  const tick: Tick = {};
  if (data.byteLength >= 17) {
    tick.token = bigEndianToInt(data.slice(0, 4));
    tick.ltp = bigEndianToInt(data.slice(4, 8));
    if (data.byteLength === 17) {
      tick.close = bigEndianToInt(data.slice(13, 17));
      tick.netChange =
        Math.round(
          (((tick.ltp - tick.close) / tick.close) * 100 + Number.EPSILON) *
          100
        ) / 100 || 0;
      if (tick.ltp > tick.close) {
        tick.changeFlag = 43; // ascii code for +
      } else if (tick.ltp < tick.close) {
        tick.changeFlag = 45; // ascii code for -
      } else {
        tick.changeFlag = 32; // no change
      }
    }
    tick.mode = MODE.LTPC;
  }
  if (data.byteLength >= 81) {
    tick.ltq = bigEndianToInt(data.slice(13, 17));
    tick.avgPrice = bigEndianToInt(data.slice(17, 21));
    tick.totalBuyQuantity = bigEndianToInt(data.slice(21, 29));
    tick.totalSellQuantity = bigEndianToInt(data.slice(29, 37));
    tick.open = bigEndianToInt(data.slice(37, 41));
    tick.high = bigEndianToInt(data.slice(41, 45));
    tick.close = bigEndianToInt(data.slice(45, 49));
    tick.low = bigEndianToInt(data.slice(49, 53));
    tick.volume = bigEndianToInt(data.slice(53, 61));
    tick.ltt = bigEndianToInt(data.slice(61, 65));
    tick.time = bigEndianToInt(data.slice(65, 69));
    tick.oi = bigEndianToInt(data.slice(69, 73));
    tick.oiDayHigh = bigEndianToInt(data.slice(73, 77));
    tick.oiDayLow = bigEndianToInt(data.slice(77, 81));
    tick.netChange =
      Math.round(
        (((tick.ltp! - tick.close) / tick.close) * 100 + Number.EPSILON) * 100
      ) / 100 || 0;
    if (tick.ltp! > tick.close) {
      tick.changeFlag = 43; // ascii code for +
    } else if (tick.ltp! < tick.close) {
      tick.changeFlag = 45; // ascii code for -
    } else {
      tick.changeFlag = 32; // no change
    }
    tick.mode = MODE.QUOTE;
  }
  if (data.byteLength === 229) {
    tick.lowerLimit = bigEndianToInt(data.slice(81, 85));
    tick.upperLimit = bigEndianToInt(data.slice(85, 89));
    const bids = [];
    const asks = [];
    for (let i = 0; i < 10; i++) {
      const quantity = bigEndianToInt(data.slice(89 + i * 14, 97 + i * 14));
      const price = bigEndianToInt(data.slice(97 + i * 14, 101 + i * 14));
      const orders = bigEndianToInt(data.slice(101 + i * 14, 103 + i * 14));
      if (i >= 5) {
        asks.push({ price, quantity, orders });
      } else {
        bids.push({ price, quantity, orders });
      }
    }
    tick.bids = bids;
    tick.asks = asks;
    tick.mode = MODE.FULL;
  }
  if (tick.close === 0) {
    tick.netChange = 0;
  }
  return tick;
}

/* GetNextBarTime ----------------------------------------
  Returns the time (in milliseconds) the next bar in chart will have.
  barTime to be in milliseconds.
*/
const getNextBarTime = (resolution: ResolutionString, barTime: number) => {
  const date = new Date(barTime);
  switch (resolution) {
    case Resolutions[1]:
      date.setMinutes(date.getMinutes() + 1);
      break;
    case Resolutions[3]:
      date.setMinutes(date.getMinutes() + 3);
      break;
    case Resolutions[5]:
      date.setMinutes(date.getMinutes() + 5);
      break;
    case Resolutions[10]:
      date.setMinutes(date.getMinutes() + 10);
      break;
    case Resolutions[15]:
      date.setMinutes(date.getMinutes() + 15);
      break;
    case Resolutions[30]:
      date.setMinutes(date.getMinutes() + 30);
      break;
    case Resolutions[60]:
      date.setHours(date.getHours() + 1);
      break;
    case Resolutions["1D"]:
      date.setDate(date.getDate() + 1);
      break;
    case Resolutions["1W"]:
      date.setDate(date.getDate() + 7);
      break;
    case Resolutions["1M"]:
      date.setMonth(date.getMonth() + 1);
      break;
    default:
      error(`[getNextBarTime] Resolution not supported: ${resolution}`);
      return undefined;
  }

  return date.getTime();
};

/* updateBarListeners ----------------------------------------
  Updates the respective listeners for SymbolToken with new/updated bars.
*/
export const updateBarSubscribers = (tick: Tick) => {
  // invalid tick
  if (!tick.token || !tick.ltp || !tick.time) return;

  const token = tick.token;
  const tradePrice = tick.ltp / 100;
  const tradeTime = tick.time * 1000; // in ms
  const tradeOI = tick.oi || 0;
  const volume = tick.volume || 0;

  configurationData.supported_resolutions?.forEach((resolution) => {
    // fetch subscription for this symbol Token
    const subscriptionItem = Store.getSocketSubscription(token, resolution);
    // no such subscription
    if (!subscriptionItem) return;

    // most recent bar
    const lastBar = subscriptionItem.lastBar; // in ms

    // default bar
    let bar: CustomBar = {
      time: tradeTime,
      open: tradePrice,
      high: tradePrice,
      low: tradePrice,
      close: tradePrice,
      volume: 0,
      fVolume: volume,
    };

    // last bar exists
    if (lastBar) {
      // next bar time
      const nextBarTime = getNextBarTime(resolution, lastBar.time); // in ms
      if (!nextBarTime) return;
      // next to next bar time
      const nextNextBarTime = getNextBarTime(resolution, nextBarTime); // in ms
      if (!nextNextBarTime) return;

      if (tradeTime >= nextBarTime && tradeTime < nextNextBarTime) {
        // tick belongs to immediate next bar. hence create new bar
        bar.time = nextBarTime;
        log(
          `🆕 Creating next bar for token: ${token}, resolution: ${resolution}. Trade time: ${tradeTime}, Last bar time: ${lastBar.time}, Next bar time: ${nextBarTime} `
        );
      } else if (tradeTime < nextBarTime) {
        // tick belongs to same bar. hence update

        let fVolume = lastBar.fVolume;
        if (!fVolume) {
          fVolume = volume - (lastBar.volume || 0);
        }

        bar = {
          ...lastBar,
          high: Math.max(lastBar.high, tradePrice),
          low: Math.min(lastBar.low, tradePrice),
          close: tradePrice,
          volume: volume - fVolume,
          fVolume: fVolume,
        };
        log(
          `🔧 Updating last bar for token: ${token}, resolution: ${resolution}. Trade time: ${tradeTime}, Last bar time: ${lastBar.time}, Next bar time: ${nextBarTime} `
        );
      } else {
        // entirely new bar. may be different day

        // if is daily, weekly or monthly resolution, using only date part for UTC date creation.
        // !Required by tv charts.
        if (/[DWM]/.test(resolution)) {
          bar.time =
            dayjs.utc(dayjs(tradeTime).tz().format("YYYY-MM-DD")).unix() * 1000;
        } else if (+resolution >= 60) {
          // hours
          bar.time =
            dayjs(tradeTime).tz().set("second", 0).set("minute", 0).unix() * 1000;
        } else {
          // minutes
          bar.time = dayjs(tradeTime).tz().set("second", 0).unix() * 1000;
        }
        log(
          `🆕 Creating new bar for token: ${token}, resolution: ${resolution}. Trade time: ${tradeTime}, Last bar time: ${lastBar.time}, Next bar time: ${nextBarTime} `
        );
      }
    }

    // updating last bar in subscriptions
    subscriptionItem.lastBar = bar;
    Store.setSocketSubscription(token, resolution, subscriptionItem);

    // Send data to every subscriber of that symbol
    subscriptionItem.handlers.forEach((handler) => {
      const symbol = handler.id.split("_");
      const [_xchng, _tkr, _ticker, isOI] = extractDataFromSymbolName(symbol[0]);
      if (isOI) {
        // if symbol id is OI type

        const oiBar = { ...bar };
        oiBar.close = tradeOI;
        handler.callback(oiBar);
      } else {
        handler.callback(bar);
      }
    });

    log(
      `📣 Updated all subscribers for token: ${token} & resolution: ${resolution} with bar:`,
      bar
    );
  });
};

// Get holidays ---------------------------------------------------------
export const getHolidaysFromBackend =
  async (): Promise<IHolidaysAndSpecialTradingDays> => {
    try {
      let response = await fetch(`${process.env.OMS_URL}info/holidays`, {
        headers: {
          session: store.getAuthSession(),
          token: store.getAuthToken(),
        },
      });
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 401) {
          handle401();
        }
        throw new Error(JSON.stringify(response));
      }
      return data.data;
    } catch (err) {
      error("⛔ error fetching holidays:", err);
      return { holidays: {}, specialTradingDays: {} };
    }
  };

// Returns trading view supported corrections and session holidays data -----------------
export const formatHolidaysAndCorrections = (
  response: IHolidaysAndSpecialTradingDays
): IHolidaysAndCorrections => {
  const result: IHolidaysAndCorrections = {
    holidays: "",
    corrections: "",
  };
  // holidays
  const strDates = Object.keys(response.holidays).map((date) =>
    dayjs(date, "DD-MM-YYYY").tz().format("YYYYMMDD")
  );
  result.holidays = strDates.join(",");

  // corrections
  const corrections: string[] = [];
  Object.entries(response.specialTradingDays).map((entry): void => {
    const date = dayjs(entry[0], "DD-MM-YYYY").tz().format("YYYYMMDD");

    let sessions: string[] = [];
    entry[1].map(([time, minutes]) => {
      const [hour, minute] = time.split(":");

      // 2006-01-02 is just the random date here.
      const startTs = dayjs("2006-01-02").tz()
        .set("hour", +hour)
        .set("minute", +minute);
      const endTs = startTs.add(minutes, "minute");

      const startTime = startTs.format("HHmm");
      const endTime = endTs.format("HHmm");

      sessions.push(`${startTime}-${endTime}`);
    });

    const sessionsStr = sessions.join(",");
    corrections.push(`${sessionsStr}:${date}`);
  });
  result.corrections = corrections.join(";");
  return result;
};

import {
  Bar,
  DatafeedConfiguration,
  ErrorCallback,
  HistoryCallback,
  LibrarySymbolInfo,
  OnReadyCallback,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback,
  SubscribeBarsCallback,
  SymbolResolveExtension,
} from "../charting_library/charting_library";
import {
  extractDataFromSymbolName,
  getSegment,
  getBarsFromBackend,
  prepareDataRanges,
  formatHolidaysAndCorrections,
} from "./helpers";
import Store from "./store";
import { Segment, Resolutions, CODE, MODE } from "../constants";
import dayjs from "dayjs";
import { log } from "./helpers";
import { emit } from "./socket";
import {
  IHolidaysAndCorrections,
  IHolidaysAndSpecialTradingDays,
} from "../types";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Kolkata");

// ---------------------------------X---------------------------------------

let holidaysAndCorrections: IHolidaysAndCorrections;
let holidaysNspecialDays: IHolidaysAndSpecialTradingDays;

// OnReady datafeed configuration data
export const configurationData: DatafeedConfiguration = {
  // !if more resolutions are added here make sure to handle their next bar creation
  // ref methods: getNextBarTime(),updateBarSubscribers(),getBackendResolution(),resolveSymbol()
  supported_resolutions: [
    Resolutions[1],
    Resolutions[3],
    Resolutions[5],
    Resolutions[10],
    Resolutions[15],
    Resolutions[30],
    Resolutions[60],
    Resolutions["1D"],
    Resolutions["1W"],
    Resolutions["1M"],
  ],
  exchanges: [
    {
      value: "NSE",
      name: "NSE",
      desc: "National Stock Exchange of India Ltd.",
    },
  ],
};

// onReady--------------------------------------------------
export const onReady = async (callback: OnReadyCallback) => {
  log("[👌 onReady]: Method call");

  holidaysNspecialDays = await Store.getHolidaysFromBackend();
  holidaysAndCorrections = formatHolidaysAndCorrections(holidaysNspecialDays);

  setTimeout(() => callback(configurationData));
};

// searchSymbols--------------------------------------------
export const searchSymbols = (
  userInput: string,
  exchange: string,
  symbolType: string,
  onResultReadyCallback: SearchSymbolsCallback
) => {
  log("[🔍 searchSymbols]: Method call");

  // searching
  const newSymbols = Store.getSearchInstance().search(userInput);


  // formatting
  // !segment2 will only be needed in cases of indices, to determine whether its NSE or BSE
  const matchedSymbols = newSymbols.map((e) => ({
    ticker: `${e.segment2}|${e.exchange}:${e.tradingSymbol}`,
    symbol: e.tradingSymbol,
    full_name: e.niceName,
    description: e.niceName,
    exchange: e.exchange,
    type: e.type,
    token: e.exchangeToken,
    lotSize: e.lotSize,
  }));

  // Only showing top 30 records
  onResultReadyCallback(matchedSymbols.slice(0, 30));
};

/* resolveSymbols --------------------------------------------
  !Have noticed that resolveSymbol caches the data returned
  and may not be called if any previously used symbol is requested
*/
export const resolveSymbol = (
  symbolName: string,
  onSymbolResolvedCallback: ResolveCallback,
  onResolveErrorCallback: ErrorCallback,
  extension?: SymbolResolveExtension | undefined
) => {
  log("[📋 resolveSymbol]: Method call, Symbol:", symbolName);

  const [_segment2, exchange, ticker, _isOpenInterest] =
    extractDataFromSymbolName(symbolName);

  const segment = getSegment(ticker, exchange);
  let name = "";
  let description = "";
  let type = "";

  const symbolItem = Store.getSearchInstance().get(ticker, segment, exchange);

  // No such symbol found in search
  if (symbolItem.instrumentToken || symbolItem.tradingSymbol) {
    name = symbolItem.fullName;
    description = symbolItem.niceName;
    type = symbolItem.type;
  }

  // caching the symbolToken
  Store.setTokenToTicker(symbolName, symbolItem.instrumentToken);

  const symbolInfo: LibrarySymbolInfo = {
    ticker: symbolName,
    name,
    description,
    type,
    has_empty_bars: false,
    session: "0915-1530",
    timezone: "Asia/Kolkata",
    exchange,
    minmov: 1,
    pricescale: 100,
    intraday_multipliers: ["1", "3", "5", "10", "15", "30", "60"],
    daily_multipliers: ["1"],
    monthly_multipliers: ["1"],
    weekly_multipliers: ["1"],
    session_holidays: holidaysAndCorrections?.holidays,
    corrections: holidaysAndCorrections?.corrections,
    listed_exchange: symbolItem.segment2,
    // !if modified, modifications needed in updateBarSubscribers(),getBackendResolution().
    // read doc.
    has_intraday: true,
    has_daily: true,
    has_weekly_and_monthly: true,
    supported_resolutions: configurationData.supported_resolutions!,
    volume_precision: 2,
    data_status: "streaming",
    format: "price",
  };

  setTimeout(() => onSymbolResolvedCallback(symbolInfo));
};

// getBars ----------------------------------------------------
export const getBars = async (
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  periodParams: PeriodParams,
  onHistoryCallback: HistoryCallback,
  onErrorCallback: ErrorCallback
) => {
  let { from, to, firstDataRequest } = periodParams;
  log(
    "[📊 getBars]: Method call, ",
    "Symbol:",
    symbolInfo.ticker,
    "Resolution:",
    resolution,
    "From:",
    from,
    "To",
    to
  );

  if (!symbolInfo.ticker) {
    onErrorCallback("Ticker is unknown");
    return;
  }

  // is OI required
  var [_segment2, _xchng, _tkr, isOI] = extractDataFromSymbolName(
    symbolInfo.ticker
  );

  const symbolToken = Store.getTokenFromTicker(symbolInfo.ticker);

  if (!symbolToken) {
    onErrorCallback("Token is unknown");
    return;
  }

  // !Correction. If 'INDICES' we are using exchange as 'NSE' in backend
  let exchange = <Segment>symbolInfo.exchange;
  if (exchange === Segment.INDICES) {
    exchange = <Segment>symbolInfo.listed_exchange;
  }

  try {
    // formatting bars as per requried.
    let bars: Bar[] = [];

    //  dividing into chunks so that further requests goes through cloudfront cache.
    const ranges = await prepareDataRanges(from, to, resolution);
    if (ranges.length) {
      // will keep a count for tries we did to get alteast some data to return
      let noDataCounter = 3;

      // jump in if bars length is 0 and and we still have tries left
      while (!bars.length && noDataCounter) {
        const promises = ranges.map((range) =>
          getBarsFromBackend(
            exchange,
            symbolToken,
            resolution,
            range.from,
            range.to,
            isOI
          )
        );

        const results = await Promise.allSettled(promises);

        // daily, weekly or monthly resolution
        const isDWM = /[DWM]/.test(resolution);

        results.forEach((result) => {
          // request was success
          if (result.status === "fulfilled") {
            // formatting bars for as per library requirements

            result.value.forEach((backendBar) => {
              let barTime = dayjs(backendBar[0]).tz();
              // if is daily, weekly or monthly resolution, using only date part for UTC date creation.
              // !Required by tv charts.
              if (isDWM) {
                barTime = dayjs.utc(backendBar[0].substring(0, 10));
              }

              const newBar: Bar = {
                time: barTime.unix() * 1000, // in milliseconds
                open: backendBar[1] / 100 || bars[bars.length - 1]?.close || 0,
                high: backendBar[2] / 100 || bars[bars.length - 1]?.close || 0,
                low: backendBar[3] / 100 || bars[bars.length - 1]?.close || 0,
                // choose previous close if zero
                close: backendBar[4] / 100 || bars[bars.length - 1]?.close || 0,
              };

              // use oi value for close if symbol type is OI
              if (isOI) {
                newBar.close = backendBar[6] || 0;
              }

              // set volume only if not an index
              if (symbolInfo.exchange !== Segment.INDICES) {
                newBar.volume = backendBar[5];
              }

              bars.push(newBar);
            });
          }
        });

        // preparing for next iteration
        // expanding 'from' to decent -5 days, might give some result.
        if (isDWM) {
          // not required for DMW data since they already have huge bracket.
          break;
        }
        ranges[0].from = ranges[0].from - 86400 * 5;
        noDataCounter--;
      }
    }

    // no bars
    if (bars.length === 0) {
      onHistoryCallback([], { noData: true });
      return;
    }

    // caching the last bar
    if (firstDataRequest) {
      Store.setLastBar(resolution, symbolToken, { ...bars[bars.length - 1] });
    }

    onHistoryCallback(bars);
  } catch (err) {
    log("[😰 getBars]:", err);
    onErrorCallback(err);
  }
};

// subscribeBars ----------------------------------------------
export const subscribeBars = (
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  onRealtimeCallback: SubscribeBarsCallback,
  subscriberUID: string,
  onResetCacheNeededCallback: () => void
) => {
  log("[🔔 subscribeBars]: Method call, subscriberUID:", subscriberUID);
  // subscrition handler

  if (!symbolInfo.ticker) {
    return;
  }

  const symbolToken = Store.getTokenFromTicker(symbolInfo.ticker);

  if (!symbolToken) {
    return;
  }

  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback,
  };

  // fetch old subscription
  let subscriptionItem = Store.getSocketSubscription(symbolToken, resolution);

  // Already subscribed, use the existing subscription
  if (subscriptionItem) {
    log(
      "Subscription already present. pushing new handler",
      symbolToken,
      resolution
    );
    subscriptionItem.handlers.push(handler);
    Store.setSocketSubscription(symbolToken, resolution, subscriptionItem);
    Store.setTokenToSubid(subscriberUID, symbolToken);
    return;
  }

  // new subscription
  subscriptionItem = {
    subscriberUID,
    resolution,
    lastBar: Store.getLastBar(resolution, symbolToken),
    handlers: [handler],
  };

  // save info
  Store.setSocketSubscription(symbolToken, resolution, subscriptionItem);
  Store.setTokenToSubid(subscriberUID, symbolToken);

  // Inform socket
  emit({
    code: CODE.SUB,
    mode: MODE.FULL,
    full: [symbolToken],
  });

  log("Subs State", Store.getAllSocketSubscriptions());
};

// unsubscribeBars -------------------------------------------
export const unsubscribeBars = (subscriberUID: string) => {
  // fetching what token this subscriberID subscribed to
  const symbolToken = Store.getTokenFromSubid(subscriberUID)!;
  log(
    "[🔕 unsubscribeBars]: Method call, subscriberUID:",
    subscriberUID,
    symbolToken
  );

  // all subscriptions.  token>resolution>subscriptionItem
  const subscriptions = Store.getAllSocketSubscriptions();

  // resolution>subscriptionItem
  const resolution_subscription = subscriptions.get(symbolToken);
  for (const resolution of resolution_subscription!.keys()) {
    // subscriptionItem
    const subscriptionItem = resolution_subscription?.get(resolution);
    if (!subscriptionItem) return;

    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      // Remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        resolution_subscription?.delete(resolution);

        if (subscriptions.get(symbolToken)?.size === 0) {
          // is no more subscriptions for this token. remove from map
          // Unsubscribe from the channel if it is the last handler
          emit({
            code: CODE.UNSUB,
            mode: MODE.FULL,
            full: [Store.getTokenFromSubid(subscriberUID)!],
          });
          subscriptions.delete(symbolToken);
        }
        log("Subs State", Store.getAllSocketSubscriptions());
        return;
      }
    }
  }
};
